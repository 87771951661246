import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Overhead Squats 4×8\\@70%`}</p>
    <p>{`OH Band Pull Aparts 4×8`}</p>
    <p>{`then,`}</p>
    <p>{`5 Rounds for time:`}</p>
    <p>{`10ft DB OH Walking Lunge, Right Arm (35/25)(RX+ 50/35)`}</p>
    <p>{`10ft DB OH Walking Lunge, Left Arm`}</p>
    <p>{`15-T2B`}</p>
    <p>{`10-Hang Power Snatch (75/55)(RX+ 95/65)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      